<template>
  <v-app class="lato-font">
    <div class="row marg-0">
      <div class="col-md-8 col-sm-12 col-12  padd-r-40 padd-l-0 padd-t-128 padd-r-0-mob">
        <div>
          <div class="row marg-0 align-center">
            <span class="fsize24 clr-000000">Your profile</span>
          </div>
          <div class="marg-t-22">
            <span class="fsize14 clr-55585a">Martial Status</span>
            <span class="f-right">
              <span class="checkbox-center">
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  checked
                  class="h-18 w-18"
                />
              </span>
              <label class="marg-l-8 marg-0 w-46 fsize14">Single</label>
              <span class="checkbox-center marg-l-36">
                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  checked
                  class="h-18 w-18"
                />
              </span>
              <label class="marg-l-8 fsize14 marg-0">Married</label>
            </span>
          </div>
          <hr class="lineBelowHead marg-0 marg-t-20" />
          <div class="marg-t-28">
            <span class="fsize20 clr-000000">Parents</span>
          </div>
          <div class="row marg-0 marg-t-12">
            <div class="col-6 max-w-none padd-0">
              <div class="h-64">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 clr-55585a">Father's first Name</span>
                </div>
                <input
                  type="text"
                  class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                  placeholder=""
                  autocomplete="off"
                  onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                  v-model="user.fatherfstname"
                  id="fatherfstname"
                  value="fatherfstname"
                  :class="{
                    'is-invalid': submitted && $v.user.fatherfstname.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.fatherfstname.$error"
                  class="invalid-feedback marg-0 l-height-20"
                >
                  <span
                    v-if="!$v.user.fatherfstname.required"
                    class="validatemessage"
                    >Please enter your Father's first Name</span
                  >
                </div>
              </div>
              <div class="h-64 marg-t-16">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 clr-55585a">Mother's first Name</span>
                </div>
                <input
                  type="text"
                  class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                  placeholder=""
                  autocomplete="off"
                  onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                  v-model="user.motherfstname"
                  id="motherfstname"
                  value="motherfstname"
                  :class="{
                    'is-invalid': submitted && $v.user.motherfstname.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.motherfstname.$error"
                  class="invalid-feedback marg-0 l-height-20"
                >
                  <span
                    v-if="!$v.user.motherfstname.required"
                    class="validatemessage"
                    >Please enter your Mother's first Name</span
                  >
                </div>
              </div>
            </div>
            <div class="col-6 max-w-none padd-0">
              <div class="h-64">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 clr-55585a">Father's last Name</span>
                </div>

                <input
                  type="text"
                  class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                  placeholder=""
                  autocomplete="off"
                  onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                  v-model="user.fatherlstname"
                  id="fatherlstname"
                  value="fatherlstname"
                  :class="{
                    'is-invalid': submitted && $v.user.fatherlstname.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.fatherlstname.$error"
                  class="invalid-feedback marg-0 l-height-20"
                >
                  <span
                    v-if="!$v.user.fatherlstname.required"
                    class="validatemessage"
                    >Please enter your Father's last Name</span
                  >
                </div>
              </div>
              <div class="h-64 marg-t-16">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 clr-55585a">Mother's last Name</span>
                </div>

                <input
                  type="text"
                  class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado w-100per"
                  placeholder=""
                  autocomplete="off"
                  onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode==32)"
                  v-model="user.motherlstname"
                  id="motherlstname"
                  value="motherlstname"
                  :class="{
                    'is-invalid': submitted && $v.user.motherlstname.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.user.motherlstname.$error"
                  class="invalid-feedback marg-0 l-height-20"
                >
                  <span
                    v-if="!$v.user.motherlstname.required"
                    class="validatemessage"
                    >Please enter your Mother's last Name</span
                  >
                </div>
              </div>
            </div>
          </div>
          <hr class="lineBelowHead marg-0 marg-t-40" />
          <div class="marg-t-28">
            <span class="fsize20 clr-000000">Background</span>
          </div>
          <div class="row marg-0 marg-t-12">
            <div class="col-6 max-w-none padd-0">
              <div class="h-64">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 clr-55585a">Annual income</span>
                </div>
                <b-form-select class="mar-l-20 w-100per" id="mwDDList">
                  <option value="1">Below 1 Lakh</option>
                  <option value="2">1 LAC to 5 Lac</option>
                  <option value="3">5 LAC to 10 Lac</option>
                  <option value="4">10 LAC to 25 Lac</option>
                  <option value="4">Above 25 Lac</option>
                </b-form-select>
              </div>
              <div class="h-64 marg-t-16">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 clr-55585a"
                    >Commodity trade classification</span
                  >
                </div>
                <b-form-select class="mar-l-20 w-100per" id="mwDDList">
                  <option value="1"></option>
                  <option value="2"></option>
                </b-form-select>
              </div>
              <div class="h-64 marg-t-16">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 clr-55585a"
                    >Funds and Securities settlement performance</span
                  >
                </div>
                <b-form-select class="mar-l-20 w-100per" id="mwDDList">
                  <option value="1"></option>
                  <option value="2"></option>
                </b-form-select>
              </div>
            </div>
            <div class="col-6 max-w-none padd-0">
              <div class="h-64">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 clr-55585a">Trading experience</span>
                </div>
                <b-form-select class="mar-l-20 w-100per" id="mwDDList">
                  <option value="1">None of the above</option>
                  <option value="2">Less than 1 Year</option>
                  <option value="2">2-5 Years</option>
                  <option value="2">5-10 Years</option>
                  <option value="2">10-20 Years</option>
                  <option value="2">20-25 Years</option>
                  <option value="2">More than 25 Years</option>
                </b-form-select>
              </div>
              <div class="h-64 marg-t-16">
                <div class="l-height-16 marg-b-4">
                  <span class="fsize14 clr-55585a">Occupation</span>
                </div>
                <b-form-select class="mar-l-20 w-100per" id="mwDDList">
                  <option value="1">Private Sector</option>
                  <option value="2">Goverment Service</option>
                  <option value="3">Public Sector</option>
                  <option value="4">Agriculturalist</option>
                  <option value="5">Professional</option>
                  <option value="6">Business</option>
                  <option value="7">House Wife</option>
                  <option value="8">Retired</option>
                  <option value="9">Student</option>
                  <option value="10">Others</option>
                </b-form-select>
              </div>
            </div>
          </div>
          <hr class="lineBelowHead marg-0 marg-t-40" />
          <div class="row marg-0 marg-t-40 marg-b-40">
            <div class="col-md-6 padd-0">
              <div class="">
                <div class="h-48">
                  <label class="fsize20 clr-000000 marg-0"
                    >Are you a politically exposed
                    <label class="marg-0"> person? </label></label
                  >
                </div>
              </div>
            </div>
            <div class="col-md-6 padd-0">
              <div class="">
                <b-form-select
                  class="mar-l-20 w-100per "
                  id="mwDDList"
                  v-model="selectedValue"
                >
                  <option value="1">No</option>
                  <option value="2">Yes</option>
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12   padd-0 padd-0-30 backgroundclr-efef padd-t-1-mob">
        <div class="marg-t-44 backgroundclr-fff padd-t-20 padd-l-20 padd-b-75">
          <div class="">
            <img class="profileimg" src="..\assets\Profileicon.svg" alt="" />
            <span class="fsize14 fw-bold clr-000000 margin-l-12"
              >Avinash Kumar</span
            >
          </div>
          <div class="marg-t-24 fsize14 clr-000000">
            <img class="profileimg" src="..\assets\mail-icon.svg" alt="" />
            <span class="margin-l-12">1980-09-30</span>
            <img
              class="profileimg marg-l-40"
              src="..\assets\mail-icon.svg"
              alt=""
            />
            <span class="margin-l-12">Male</span>
          </div>
          <div class="marg-t-24">
            <img class="profileimg" src="..\assets\mail-icon.svg" alt="" />
            <span class="fsize14 clr-000000 margin-l-12">
              avinash1508@gmail.com</span
            >
          </div>
          <div class="marg-t-24">
            <img class="profileimg" src="..\assets\locationicon.svg" alt="" />
            <span class="fsize14 clr-000000 margin-l-12 marg-0">
              No.45, Darshan Apartments
              <label class="padd-l-20 margin-l-12 marg-0"
                >Gandhi Nagar, West Mambalam,</label
              ><span class="padd-l-20 margin-l-12"> T.Nagar,</span> Chennai -
              600024.</span
            >
          </div>
        </div>
        <div class="marg-t-18">
          <span class="fsize14 clr-55585a"> Your PAN </span>
        </div>
        <div class="padd-t-6">
          <span class="clr-0073bc fsize24">KKHAQ7832T</span>
        </div>
        <div class="marg-t-18 h-44">
          <p class="fsize12 clr-000000 marg-0">
            <label class="marg-0">
              This PAN should belongs to you, the applicant.</label
            >
            <label class="marg-0">
              If it does not, <span class="clr-0073bc">start over</span>.</label
            >
          </p>
        </div>
        <div class="marg-t-8">
          <!-- <router-link
            to="/bank"
            active-class="active"
            tag="button"
            exact
            class="btn-color fsize18 padd-0-16 bor-radius h-48"
          >
            Continue</router-links
          > -->
          <button
            class="btn-color fsize18 padd-0-16 bor-radius h-40 w-100per"
            @click="proFilepge()"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      selectedValue: 1,
      user: {
        fatherfstname: this.fatherfstname,
        motherfstname: this.motherfstname,
        fatherlstname: this.fatherlstname,
        motherlstname: this.motherlstname,
      },

      fatherfstname: null,
      submitted: true,
      motherfstname: null,
      fatherlstname: null,
      motherlstname: null,
  };
  },
  validations: {
    user: {
      fatherfstname: { required },
      motherfstname: { required },
      fatherlstname: { required },
      motherlstname: { required },
    },
  },
  methods: {
    proFilepge() {
      this.submitted = true;
      //Error catch user id is invalid//
      this.loader = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$router.push("/bank");
    },
  },
};
</script>

<style>
</style>